// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ambassadors-tsx": () => import("./../../../src/pages/ambassadors.tsx" /* webpackChunkName: "component---src-pages-ambassadors-tsx" */),
  "component---src-pages-cambridge-guide-tsx": () => import("./../../../src/pages/cambridge-guide.tsx" /* webpackChunkName: "component---src-pages-cambridge-guide-tsx" */),
  "component---src-pages-download-bike-seat-tsx": () => import("./../../../src/pages/download/bike-seat.tsx" /* webpackChunkName: "component---src-pages-download-bike-seat-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/FAQ.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-riders-tsx": () => import("./../../../src/pages/riders.tsx" /* webpackChunkName: "component---src-pages-riders-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

